.cyclone {
  &-screen-login-logo {
    border-radius: 20%;
  }

  &-header {
    padding: 0 1.5em 0 0 !important;

    &-backHome {
      width: 249px;
      padding-left: 1.5em;
      color: #fff !important;
      background-color: #007eb8;
    }
  }
}

@primary-color: #01adee;@secondary-color: rgba(0,107,161,.7);@layout-header-background: @primary-color;@layout-header-color: #fff;@layout-sider-background: @primary-color;@layout-sider-background-light: @primary-color;@layout-trigger-height: 0;@menu-bg: @primary-color;@menu-item-color: #fff;@menu-item-active-bg: @secondary-color;@menu-highlight-color: #fff;@layout-trigger-background-light: @primary-color;