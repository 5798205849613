body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@primary-color: #01adee;@secondary-color: rgba(0,107,161,.7);@layout-header-background: @primary-color;@layout-header-color: #fff;@layout-sider-background: @primary-color;@layout-sider-background-light: @primary-color;@layout-trigger-height: 0;@menu-bg: @primary-color;@menu-item-color: #fff;@menu-item-active-bg: @secondary-color;@menu-highlight-color: #fff;@layout-trigger-background-light: @primary-color;